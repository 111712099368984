import styled from '@emotion/styled';
import { PropType } from '../../gatsby-plugin-theme-ui';

const HashTags = styled.ul<PropType>`
  list-style: none;
  li {
    display: inline-block;
    margin: ${p => p.theme.sizing.spacing2};
    padding: ${p => p.theme.sizing.spacing1};
    font-weight: 700;
    font-size: ${p => p.theme.sizing.fontSize0};
    background-color: ${p => p.theme.colors.backgroundAccent};
    color: ${p => p.theme.colors.primary};
    border-radius: ${p => p.theme.sizing.borderRadiusNormal};
    opacity: 0.5;
    transition: all 0.3s ease-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transition: all 0.2s ease-in;
    }
  }
`;

export default HashTags;
