import { css } from "@emotion/core";
import theme from "../gatsby-plugin-theme-ui";

export const globalStyles = css`
  @import "/fonts/estedad/stylesheet.css";
  /**
   * Thanks to Benjamin De Cock
   * https://gist.github.com/bendc/ac03faac0bf2aee25b49e5fd260a727d
   */
  :root {
    --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-display: block;
  }

  :root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    cursor: default;
    font-size: ${theme.sizing.fontSize0};
    line-height: ${theme.sizing.lineHeightNormal};
  }

  body {
    font-family: "Estedad", sans-serif;
    font-size: ${theme.sizing.fontSize3};
    margin: 0;
    font-weight: 400;
    height: 100%;
    direction: ${theme.isRtl ? "rtl" : "ltr"};
  }

  article {
    word-break: break-word;
    direction: ${theme.isRtl ? "rtl" : "ltr"};
    text-align: ${theme.isRtl ? "right" : "left"};
    line-height: ${theme.sizing.lineHeightRelaxed};
  }

  button,
  a {
    text-decoration: none;
    cursor: pointer;
    direction: ${theme.isRtl ? "rtl" : "ltr"};
    text-align: ${theme.isRtl ? "right" : "left"};
  }

  a:focus {
    outline: none;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
    align-self: center;
  }

  input,
  textarea,
  select,
  button {
    font-family: "Estedad", sans-serif;
  }

  .underline {
    text-decoration: underline;
  }

  button,
  input,
  select,
  textarea {
    color: inherit;
    font-family: "Estedad", sans-serif;
    font-style: inherit;
    font-weight: inherit;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace;
    direction: ltr;
    unicode-bidi: isolate;
    overflow-x: auto;
  }

  code {
    padding: ${theme.sizing.spacing1} ${theme.sizing.spacing2};
    background-color: ${theme.colors.backgroundAccent};
    color: ${theme.colors.grey};
    border-radius: ${theme.sizing.spacing8};
    margin: 0 ${theme.sizing.spacing1};
  }

  ol,
  ul {
    direction: ${theme.isRtl ? "rtl" : "ltr"};
    text-align: ${theme.isRtl ? "right" : "left"};
  }

  fieldset,
  button {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  audio:not([controls]) {
    display: none;
  }

  details {
    display: block;
  }

  input {
    &:focus,
    &:active {
      outline: none;
    }

    &[type="number"] {
      width: auto;
    }
  }

  img.Image__Zoom ~ div {
    background: transparent !important;
  }
`;
