import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import Section from '@components/Section';
import SocialLinks from '@components/SocialLinks';
import theme, { PropType } from '../../gatsby-plugin-theme-ui';

import mediaqueries from '@styles/media';

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            social {
              url
              name
            }
          }
        }
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: ASC }
      filter: { frontmatter: { date: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            date
          }
        }
      }
    }
  }
`;

const Footer: React.FC<{}> = () => {
  const results = useStaticQuery(siteQuery);
  const { social } = results.allSite.edges[0].node.siteMetadata;

  const copyrightDate = (() => {
    const { edges } = results.allMdx;
    const years = [0, edges.length - 1].map(edge =>
      new Date(edges[edge].node.frontmatter.date).toLocaleDateString(
        theme.translations.localeString,
        {
          year: 'numeric',
        },
      ),
    );
    return years[0] === years[1] ? `${years[0]}` : `${years[0]} – ${years[1]}`;
  })();

  return (
    <>
      <Section narrow>
        <HoritzontalRule />
        <FooterContainer>
          <FooterText>
            {theme.translations.navigation.footerText(copyrightDate)}
          </FooterText>
          <div>
            <SocialLinks links={social} />
          </div>
        </FooterContainer>
      </Section>
    </>
  );
};

export default Footer;

const FooterContainer = styled.div<PropType>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${p => p.theme.sizing.spacing32};
  color: ${p => p.theme.colors.grey};

  ${mediaqueries.tablet`
    flex-direction: column;
    padding-bottom: 100px;
  `}

  ${mediaqueries.phablet`
    padding-bottom: 50px;
  `}
`;

const HoritzontalRule = styled.div<PropType>`
  position: relative;
  margin: ${p => p.theme.sizing.spacing32} auto ${p => p.theme.sizing.spacing24};
  border-bottom: 1px solid ${p => p.theme.colors.track};

  ${mediaqueries.tablet`
    margin: 60px auto;
  `}

  ${mediaqueries.phablet`
    display: none;
  `}
`;

const FooterText = styled.div<PropType>`
  ${mediaqueries.tablet`
    margin-bottom: 80px;
  `}

  ${mediaqueries.phablet`
    margin: 120px auto 100px;
  `}
`;
