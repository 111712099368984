import React, { useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import mediaqueries from '@styles/media';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Container = styled.div<PropType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.theme.sizing.maxWidthFull};
  height: ${p => p.theme.sizing.maxWidthFull};
  background: #ccc;
  color: #898989;
  font-size: ${p => p.theme.sizing.fontSize2};
  font-weight: 600;

  ${mediaqueries.phablet`
    font-size: 28px;
  `}
`;

const ImagePlaceholder: React.FC<{}> = props => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setDimensions(containerRef.current.getBoundingClientRect());

    const handleResize = () =>
      setDimensions(containerRef.current.getBoundingClientRect());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container ref={containerRef} {...props}>
      <div>
        {dimensions.width} x {dimensions.height}
      </div>
    </Container>
  );
};

export default ImagePlaceholder;
