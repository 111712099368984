import React from 'react';
import styled from '@emotion/styled';
import theme from '../../gatsby-plugin-theme-ui';

const PostInfo = ({ article, size }) => {
  let level;

  switch (article.level) {
    case 'beginner':
      level = 'تازه‌کارهای';
      break;
    case 'intermediate':
      level = 'باتجربه‌ترهای';
      break;
    default:
      level = article.level;
      break;
  }

  const catCount = article.categories.length;

  let categories = [...article.categories];
  let text = '';

  if (catCount === 1) {
    text = categories[0];
  } else if (catCount > 1) {
    const lastCat = categories.pop();
    text = categories.join('،');
    text = `${text} و ${lastCat}`;
  }

  let prep = '';
  if (catCount > 1) {
    prep = 'موضوعات';
  }

  return (
    <Info size={size}>
      {theme.translations.formatDate(article.date)}
      {' — '}
      <strong>
        {article.timeToRead.toLocaleString(theme.translations.localeString)}{' '}
        {theme.translations.articles.timeToRead}
      </strong>
      {' — '}
      <small>
        برای {level} علاقه‌مند به {prep} {text}
      </small>
    </Info>
  );
};

const Info = styled.div<{ size: number }>`
  font-size: ${p => p.size || 11}px;
`;

export default PostInfo;
