import React from 'react';
import styled from '@emotion/styled';

import mediaqueries from '@styles/media';

import { Icon } from '@types';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Logo: Icon = ({ fill = 'white' }) => {
  return (
    <LogoContainer>
      <h2>آیین سعیدی، یه برنامه‌نویس و بلاگر</h2>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div<PropType>`
  h2 {
    color: ${p => p.theme.colors.primary};
  }

  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
