import styled from '@emotion/styled';
import { css } from '@emotion/core';

import mediaqueries from '@styles/media';
import { PropType } from '../../gatsby-plugin-theme-ui';

/**
 * Example:
 * <Heading.h1>Lorem Ipsum</Heading.h1>
 */

const commonStyles = p => css`
  font-weight: bold;
  color: ${p.theme.colors.primary};
  font-family: ${p.theme.fonts.serif};
`;

const h1 = styled.h1<PropType>`
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize6};
  line-height: 1.15;
  ${commonStyles};

  ${mediaqueries.desktop`
    font-size: ${p => p.theme.sizing.fontSize5};
    line-height: 1.2;
  `};

  ${mediaqueries.phablet`
    font-size: ${p => p.theme.sizing.fontSize4};
    line-height: 1.3;
  `};
`;

const h2 = styled.h2<PropType>`
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize5};
  line-height: 1.333;
  ${commonStyles};

  ${mediaqueries.desktop`
    font-size: ${p => p.theme.sizing.fontSize4};
  `};

  ${mediaqueries.tablet`
    font-size: ${p => p.theme.sizing.fontSize3};
    line-height: 1.45;
  `};

  ${mediaqueries.phablet`
    font-size: ${p => p.theme.sizing.fontSize2};
  `};
`;

const h3 = styled.h3<PropType>`
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize4};
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: ${p => p.theme.sizing.fontSize3};
  `};

  ${mediaqueries.phablet`
    font-size: ${p => p.theme.sizing.fontSize2};
  `};
`;

const h4 = styled.h4<PropType>`
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize3};
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: ${p => p.theme.sizing.fontSize2};
  `};
`;

const h5 = styled.h5<PropType>`
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize2};
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: ${p => p.theme.sizing.fontSize1};
  `};
`;

const h6 = styled.h6<PropType>`
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize1};
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: ${p => p.theme.sizing.fontSize0};
  `};
`;

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
};
